<template>
  <div
    class="time-off-approval"
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page>
      <template #page-header>
        <h2 class="page-title has-text-color-page-title">Time Off</h2>
        <r-third-level-menu />
      </template>
      <template #action-bar>
        <div class="columns">
          <div
            class="column is-1 is-flex is-align-items-center"
            v-if="checkedRows.length > 0 && !isMassUpdating"
          >
            <div class="is-flex action-text" @click="massUpdate('approved')">
              <b-icon icon="check" type="is-success"></b-icon>
              <p class="has-text-success">Approve</p>
            </div>
          </div>
          <div
            class="column is-1 is-flex action-text is-align-items-center"
            v-if="checkedRows.length > 0 && !isMassUpdating"
          >
            <div class="is-flex" @click="massUpdate('declined')">
              <b-icon icon="close" type="is-danger"></b-icon>
              <p class="has-text-danger">Decline</p>
            </div>
          </div>
          <div class="column"></div>
          <div class="column is-3">
            <b-field class="search-container">
              <b-input
                expanded
                v-model="search"
                placeholder="Search"
                type="text"
                @keydown.native.enter="searchIconClick"
                icon-right="magnify"
                @icon-right-click="searchIconClick"
                icon-right-clickable
                :loading="isLoadTimeOffApprovalList"
              ></b-input>
            </b-field>
          </div>
        </div>
      </template>
      <template #page-content>
        <div class="columns is-multiline">
          <div class="column is-12">
            <div id="table-approval-timeoff">
              <b-table
                :data="timeOffApprovalList"
                :per-page="perPage"
                @page-change="onPageChange"
                :show-detail-icon="showDetailIcon"
                ref:table
                :selected.sync="selectedTimeOff"
                hoverable
                narrowed
                backend-sorting
                :default-sort-direction="defaultSortOrder"
                :default-sort="[sortField, sortOrder]"
                checkable
                checkbox-position="left"
                :is-row-checkable="(row) => row.approvable"
                :checked-rows.sync="checkedRows"
                :loading="isMassUpdating"
                @sort="onSort"
                sticky-header
              >
                <b-table-column
                  field="time_off_type_user_id"
                  label="Request ID"
                  v-slot="props"
                  sortable
                  numeric
                  centered
                >
                  <span>
                    {{ props.row.requestTimeOffId }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="full_name"
                  label="Full Name"
                  v-slot="props"
                  sortable
                >
                  <div class="columns">
                    <div
                      class="column is-3-widescreen is-3-desktop-only is-4-tablet has-text-right-tablet-only"
                    >
                      <div class="td-profile-picture-container is-fullwidth">
                        <img
                          :src="
                            determineProfilePicture(props.row.requestedUser)
                          "
                        />
                      </div>
                    </div>
                    <div class="column is-flex is-align-items-center">
                      <span>
                        {{ props.row.requestedUser.fullName }}
                      </span>
                    </div>
                  </div>
                </b-table-column>

                <b-table-column
                  field="duration"
                  label="Duration"
                  v-slot="props"
                  sortable
                >
                  <span>
                    {{
                      formatDate(new Date(props.row.startDate), 'DD/MM/YYYY')
                    }}
                    -
                    {{ formatDate(new Date(props.row.endDate), 'DD/MM/YYYY') }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="timeoff_type_user.request_reason"
                  label="Reason"
                  v-slot="props"
                  sortable
                >
                  <span>
                    {{
                      props.row.requestReason ? props.row.requestReason : '-'
                    }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="time_off_approvals.status"
                  label="Status"
                  v-slot="props"
                  sortable
                >
                  <span
                    :class="`td-time-off-approval-status ${determineFontClassByStatus(
                      props.row.approvalStatus
                    )}`"
                  >
                    {{ props.row.approvalStatus }}
                  </span>
                </b-table-column>

                <b-table-column label="Approvable" v-slot="props" centered>
                  <span>
                    {{ props.row.approvable ? 'Yes' : 'No' }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="time_off_approvals.created_at"
                  label="Created At"
                  v-slot="props"
                  sortable
                >
                  <span>
                    {{
                      formatDate(new Date(props.row.createdAt), 'DD/MM/YYYY')
                    }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="action"
                  label="Action"
                  centered
                  v-slot="props"
                >
                  <b-icon
                    type="is-primary"
                    icon="pencil"
                    custom-size="mdi-18px"
                    class="employment-edit-icon"
                    @click.native="openModalDetail(props.row)"
                  />
                </b-table-column>
              </b-table>
            </div>
          </div>
          <div class="column is-12 has-text-centered" v-if="isLoadMore">
            Please Wait ...
          </div>
        </div>

        <!-- Modal to view time off approval -->
        <r-page-modal
          :prompt-on-leave="false"
          prompt-message="Are You sure?"
          v-if="isModalEditOpen"
          @hide="closeModalEdit"
          :is-modal="true"
        >
          <template #page-header>
            <h2 class="page-title">Time Off Request</h2>
          </template>
          <template #page-content>
            <div class="columns is-multiline has-text-color-label">
              <div class="column is-12">
                <div class="columns">
                  <div class="column is-2">
                    <img
                      class="selected-time-off-approval-profile-picture"
                      :src="
                        determineProfilePicture(selectedTimeOff.requestedUser)
                      "
                    />
                  </div>
                  <div class="column is-7 is-flex is-align-items-center">
                    <p>
                      <span
                        class="time-off-approval-modal-content-full-name has-text-weight-semibold"
                      >
                        {{ selectedTimeOff.requestedUser.fullName }}
                      </span>
                      <br />
                      <span
                        class="time-off-approval-modal-content-position-name"
                      >
                        {{ selectedTimeOff.requestedUser.position }}
                      </span>
                    </p>
                  </div>
                  <div
                    class="column is-3 is-flex is-align-items-center is-justify-content-flex-end"
                  >
                    <div
                      :class="`time-off-approval-modal-status-container has-text-white has-text-weight-semibold ${determineBGClassByStatus(
                        selectedTimeOff.approvalStatus
                      )}`"
                    >
                      {{ selectedTimeOff.approvalStatus }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="column is-12">
                <p class="has-text-weight-bold">
                  Request ID
                  <br />
                  <span class="has-text-weight-normal">
                    {{ selectedTimeOff.requestTimeOffId }}
                  </span>
                </p>
              </div>

              <div class="column is-12">
                <p class="has-text-weight-bold">
                  Time Off
                  <br />
                  <span class="has-text-weight-normal">
                    {{ selectedTimeOff.permissionType }}
                  </span>
                </p>
              </div>

              <div class="column is-4">
                <p class="has-text-weight-bold">
                  Start Date
                  <br />
                  <span class="has-text-weight-normal">
                    {{
                      formatDate(
                        new Date(selectedTimeOff.startDate),
                        'DD/MM/YYYY'
                      )
                    }}
                  </span>
                </p>
              </div>
              <div class="column is-6">
                <p class="has-text-weight-bold">
                  End Date
                  <br />
                  <span class="has-text-weight-normal">
                    {{
                      formatDate(
                        new Date(selectedTimeOff.endDate),
                        'DD/MM/YYYY'
                      )
                    }}
                  </span>
                </p>
              </div>

              <div class="column is-12">
                <p class="has-text-weight-bold">
                  Request Reason
                  <br />
                  <span class="has-text-weight-normal">
                    {{ selectedTimeOff.requestReason }}
                  </span>
                </p>
              </div>

              <div class="column is-12">
                <p class="has-text-weight-bold">
                  Approved/Declined Reason
                  <br />
                  <b-input
                    maxlength="200"
                    type="textarea"
                    v-model="approvalReason"
                    v-if="selectedTimeOff.approvalStatus === 'waiting'"
                    :disabled="isUpdatingDeclined || isUpdatingApproved"
                  />
                  <span class="has-text-weight-normal" v-else>
                    {{ selectedTimeOff.approvalReason }}
                  </span>
                </p>
              </div>

              <div class="column is-12" v-if="selectedTimeOff.files.length > 0">
                <p class="has-text-weight-bold">File</p>
                <br />

                <div class="tags">
                  <span
                    v-for="file in selectedTimeOff.files"
                    :key="file.id"
                    class="file-tag has-background-primary has-text-white"
                    @click="openFileUrl(file.url)"
                  >
                    {{ file.name }}
                  </span>
                </div>
              </div>
              <div class="column is-12">
                <p class="has-text-weight-bold">No Attachment</p>
              </div>

              <div class="column is-12">
                <p class="has-text-weight-bold">Approved By</p>
                <br />
                <TimeOffApprovalHistoryComponent
                  class="has-text-color-label"
                  :approval-history="selectedTimeOff.history"
                />
              </div>

              <div
                class="column is-12"
                v-if="selectedTimeOff.approvalStatus === 'waiting'"
              >
                <div class="columns is-multiline">
                  <div class="column is-4"></div>
                  <div class="column is-4">
                    <b-button
                      outlined
                      type="is-primary"
                      class="has-text-weight-bold has-text-black"
                      @click="
                        updateStatusApproval(
                          selectedTimeOff.id,
                          'declined',
                          approvalReason
                        )
                      "
                      :disabled="disableDeclined || isUpdatingDeclined"
                    >
                      {{ isUpdatingDeclined ? 'Declining...' : 'Decline' }}
                    </b-button>
                  </div>
                  <div class="column is-">
                    <b-button
                      expanded
                      type="is-primary"
                      class="has-text-weight-bold"
                      @click="
                        updateStatusApproval(
                          selectedTimeOff.id,
                          'approved',
                          approvalReason
                        )
                      "
                      :disabled="disableApproved || isUpdatingApproved"
                    >
                      {{ isUpdatingApproved ? 'Approving...' : 'Approve' }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </r-page-modal>
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import {
  showToast,
  determineFontColorByStatus,
  determineBackgroundColorByStatus,
} from '@/services/util'
import TimeOffApprovalHistoryComponent from './TimeOffApprovalHistoryComponent'

export default {
  components: { TimeOffApprovalHistoryComponent },
  computed: {
    ...mapGetters({
      timeOffApprovalList: 'timeOffModule/getTimeoffApprovalList',
      timeOffTypeList: 'timeOffModule/getTimeOffTypes',
      currentUser: 'auth/currentUser',
    }),
  },
  data() {
    return {
      approvalReason: '',
      checkedRows: [],

      isUpdating: false,
      isUpdatingApproved: false,
      isUpdatingDeclined: false,
      disableDeclined: false,
      disableApproved: false,
      isMassUpdating: false,

      minDate: new Date(),
      open: false,
      overlay: true,
      isRightBarOpen: false,
      fullwidth: false,
      right: false,

      isPaginated: false,
      perPage: 20,
      total: 0,
      page: 0,
      lastPage: 0,

      defaultSortOrder: 'desc',
      sortField: 'updated_at',
      sortOrder: 'desc',
      search: '',

      paginationPosition: 'bottom',
      showDetailIcon: true,

      status: '',
      isLoadTimeOffApprovalList: false,
      isLoadMore: false,

      selectedTimeOff: null,
      isModalEditOpen: false,
    }
  },
  methods: {
    ...mapActions({
      actionLoadTimeOffApprovalList: 'timeOffModule/fetchListTimeOffApproval',
      actionUpdateStatusApproval: 'timeOffModule/updateStatusApproval',
      actionMassUpdateStatusApproval: 'timeOffModule/massUpdateTimeOffApproval',
    }),
    ...mapMutations({
      setTimeOffApprovalList: 'timeOffModule/setTimeoffsApprovalList',
    }),
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadTimeOffApprovalList()
        this.isLoadMore = false
      }
    },
    async massUpdate(status) {
      let ids = []
      this.checkedRows.forEach((c) => ids.push(c.id))
      // trigger loading animation
      this.isMassUpdating = true
      try {
        await this.actionMassUpdateStatusApproval({ ids, status })
        showToast('Update Success', 'is-success', 'is-bottom')
      } catch (err) {
        if (err.response.data.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        }
        console.log(err)
      }
      this.isMassUpdating = false
      this.checkedRows = []
    },
    async updateStatusApproval(id, status, reason = '') {
      const currentUserId = this.currentUser.id
      if (status === 'declined') {
        this.disableApproved = true
        this.isUpdatingDeclined = true
      } else {
        this.disableDeclined = true
        this.isUpdatingApproved = true
      }
      try {
        await this.actionUpdateStatusApproval({
          id,
          status,
          reportToId: currentUserId,
          reason,
        })
        showToast('Update Success', 'is-success', 'is-bottom')
      } catch (err) {
        if (err.response.data.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        }
        console.log(err)
      }
      this.closeModalEdit()
      if (status === 'declined') {
        this.disableApproved = false
        this.isUpdatingDeclined = false
      } else {
        this.disableDeclined = false
        this.isUpdatingApproved = false
      }
    },

    determineFontClassByStatus(status) {
      return determineFontColorByStatus(status)
    },
    determineBGClassByStatus(status) {
      return determineBackgroundColorByStatus(status)
    },
    async searchIconClick() {
      await this.resetState()
    },

    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setTimeOffApprovalList([])
      await this.loadTimeOffApprovalList()
    },

    onPageChange(page) {
      this.page = page
      this.loadTimeOffApprovalList()
    },

    openFileUrl(fileUrl) {
      let fullUrl = process.env.VUE_APP_API_URL + fileUrl
      window.open(fullUrl, '_blank')
    },

    openModalDetail(selectedTimeOff) {
      this.selectedTimeOff = selectedTimeOff
      this.isModalEditOpen = true
    },
    closeModalEdit() {
      this.selectedTimeOff = null
      this.isModalEditOpen = false
    },
    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },

    formatDate(date, format) {
      return moment(date).format(format)
    },
    async loadTimeOffApprovalList() {
      this.isLoadTimeOffApprovalList = true
      try {
        let response = await this.actionLoadTimeOffApprovalList({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: this.search,
        })
        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isLoadTimeOffApprovalList = false
    },
  },
  async mounted() {
    this.setTimeOffApprovalList([])
    await this.loadTimeOffApprovalList()
  },
}
</script>
